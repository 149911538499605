import React, { ReactNode } from 'react';
import loadable, { LoadableClassComponent } from '@loadable/component';
import appConstants from './appConstants';

// webpackPrefetch tells webpack to add the resource as <link rel="prefetch" /> which fetches the resource while the browser is idle. It's only compatible in some browsers
// webpackPreload should not be used. Instead use loadable.preload() (but sparingly) since it has cross browser compatibility
const About = loadable(() => import(/* webpackPrefetch: true */ './pages/About/About'));
const Contact = loadable(() => import('./pages/Contact/Contact'));
const CookiePolicy = loadable(() => import('./pages/CookiePolicy/CookiePolicy'));
const Download = loadable(() => import(/* webpackPrefetch: true */ './pages/Download/Download'));
const EduPricing = loadable(() => import(/* webpackPrefetch: true */ './pages/Pricing/EduPricing'));
const ExperienceTemplateLibrary = loadable(() => import('./pages/ExperienceTemplateLibrary/ExperienceTemplateLibrary'));
const HIWOrganizing = loadable(
  () => import(/* webpackPrefetch: true */ './pages/HowItWorks/HIWOrganizing/HIWOrganizing')
);
const HIWPlaying = loadable(() => import(/* webpackPrefetch: true */ './pages/HowItWorks/HIWPlaying/HIWPlaying'));
const Home = loadable(() => import('./pages/Home/Home'));
const LandingFinishPlanning = loadable(() => import('./pages/LandingFinishPlanning/LandingFinishPlanning'));
const Pricing = loadable(() => import(/* webpackPrefetch: true */ './pages/Pricing/Pricing'));
const Privacy = loadable(() => import('./pages/Privacy/Privacy'));
const SubProcessors = loadable(() => import('./pages/SubProcessors/SubProcessors'));
const TermsOfService = loadable(() => import('./pages/TermsOfService/TermsOfService'));
const CampusOrientationUses = loadable(() => import('./pages/Uses/CampusOrientationUses'));
const RecreationUses = loadable(() => import('./pages/Uses/RecreationUses'));
const K12EducationUses = loadable(() => import('./pages/Uses/K12EducationUses'), {
  resolveComponent: ({ K12EducationUses }) => K12EducationUses,
});
const OnboardingUses = loadable(() => import('./pages/Uses/OnboardingUses'));
const VirtualTeamsUses = loadable(() => import('./pages/Uses/VirtualTeamsUses'));
const VirtualRemoteSnacknation = loadable(() => import('./pages/VirtualRemoteSnacknation/VirtualRemoteSnacknation'));
const AdminLogin = loadable(() => import('./pages/AdminLogin/AdminLogin'));
const HRMultiviewLanding = loadable(() => import('./pages/MultiviewCampaign/HRMultiviewLanding'));
const HigherEdMultiviewLanding = loadable(() => import('./pages/MultiviewCampaign/HigherEdMultiviewLanding'));
const MeetingMultiviewLanding = loadable(() => import('./pages/MultiviewCampaign/MeetingMultiviewLanding'));
const CommunityMultiviewLanding = loadable(() => import('./pages/MultiviewCampaign/CommunityMultiviewLanding'));
const WhyBuyASubscription = loadable(() => import('./pages/WhyBuyASubscription/WhyBuyASubscription'));
const EduWhyBuyASubscription = loadable(() => import('./pages/WhyBuyASubscription/EduWhyBuyASubscription'));
const TourismUses = loadable(() => import('./pages/Uses/TourismUses'));
const WhatIsAnIXP = loadable(() => import('./pages/WhatIsAnIXP/WhatIsAnIXP'));

export interface Route {
  path: string;
  component: LoadableClassComponent<any> | (() => ReactNode);
}

export interface Redirect {
  from: string;
  to: string;
  // When disabled, a 301 status code is returned.
  // When enabled, a 302 status code is returned.
  temporary?: boolean;
}

export const EXPERIENCE_LIBRARY_ROUTE = {
  path: '/experience-library',
  component: ExperienceTemplateLibrary,
};

export const routes = {
  HOME: {
    path: '/',
    component: Home,
  },
  ABOUT: {
    path: '/about',
    component: About,
  },
  CONTACT: {
    path: '/contact',
    component: Contact,
  },
  PRICING: {
    path: '/pricing',
    component: Pricing,
  },
  HIW_ORGANIZING: {
    path: '/how-it-works/organizing',
    component: HIWOrganizing,
  },
  HIW_PLAYING: {
    path: '/how-it-works/playing',
    component: HIWPlaying,
  },
  TERMS_OF_SERVICE: {
    path: '/terms-of-service',
    component: TermsOfService,
  },
  PRIVACY: {
    path: '/privacy',
    component: Privacy,
  },
  COOKIE_POLICY: {
    path: '/cookie-policy',
    component: CookiePolicy,
  },
  SUB_PROCESSORS: {
    path: '/sub-processors',
    component: SubProcessors,
  },
  DOWNLOAD: {
    path: '/download',
    component: Download,
  },
  ONBOARDING_USES: {
    path: '/uses/onboarding',
    component: OnboardingUses,
  },
  RECREATION_USES: {
    path: '/uses/recreation',
    component: RecreationUses,
  },
  VIRTUAL_TEAMS_USES: {
    path: '/uses/virtual-teams',
    component: VirtualTeamsUses,
  },
  K12_EDUCATION_USES: {
    path: '/uses/education',
    component: K12EducationUses,
  },
  CAMPUS_ORIENTATION_USES: {
    path: '/uses/campus-orientation',
    component: CampusOrientationUses,
  },
  TOURISM_USES: {
    path: '/uses/tourism',
    component: TourismUses,
  },
  WHY_BUY_A_SUBSCRIPTION: {
    path: '/pricing/subscriptions',
    component: WhyBuyASubscription,
  },
  WHAT_IS_AN_IXP: {
    path: '/what-is-an-ixp',
    component: WhatIsAnIXP,
  },
  EDU_PRICING: {
    path: '/pricing/education',
    component: EduPricing,
  },
  EDU_WHY_BUY_A_SUBSCRIPTION: {
    path: '/pricing/school-wide-subscriptions',
    component: EduWhyBuyASubscription,
  },
  LANDING_FINISH_PLANNING: {
    path: '/landing/finish-your-scavenger-hunt-planning',
    component: LandingFinishPlanning,
  },
  VIRTUAL_REMOTE_SNACKNATION_1: {
    path: '/virtual-remote-snacknation-1',
    component: VirtualRemoteSnacknation,
  },
  HR_MULTIVIEW_LANDING: {
    path: '/campaign/for-hr-professionals',
    component: HRMultiviewLanding,
  },
  HIGHER_ED_MULTIVIEW_LANDING: {
    path: '/campaign/for-student-and-campus-associations',
    component: HigherEdMultiviewLanding,
  },
  MEETING_MULTIVIEW_LANDING: {
    path: '/campaign/for-meeting-professionals',
    component: MeetingMultiviewLanding,
  },
  COMMUNITY_MULTIVIEW_LANDING: {
    path: '/campaign/for-municipalities',
    component: CommunityMultiviewLanding,
  },
  ADMIN_LOGIN: {
    path: '/admin-login',
    component: AdminLogin,
  },
  // Edu Game Library must be excluded from routes
  // when redirect to Experience Library is enabled.
  EXPERIENCE_LIBRARY: EXPERIENCE_LIBRARY_ROUTE,
};

// Centralized path swapping
export const redirects: Redirect[] = [
  {
    from: '/solutions/university-college',
    to: routes.CAMPUS_ORIENTATION_USES.path,
  },
  {
    from: '/edu/how-it-works',
    to: routes.HIW_ORGANIZING.path,
  },
  {
    from: '/solutions/virtual-remote-engagement',
    to: routes.VIRTUAL_TEAMS_USES.path,
  },
  {
    from: '/careers',
    to: appConstants.CAREERS_URL,
  },
  {
    from: '/virtual-engagement',
    to: routes.VIRTUAL_TEAMS_USES.path,
  },
  {
    from: '/terms_of_service',
    to: routes.TERMS_OF_SERVICE.path,
  },
  {
    from: '/goosechase/website/terms_of_service',
    to: routes.TERMS_OF_SERVICE.path,
  },
  {
    from: '/goosechase/website/privacy',
    to: routes.PRIVACY.path,
  },
  {
    from: '/virtual-teams',
    to: routes.VIRTUAL_TEAMS_USES.path,
  },
  {
    from: '/onboarding',
    to: routes.ONBOARDING_USES.path,
  },
  {
    from: '/edu',
    to: routes.K12_EDUCATION_USES.path,
  },
  {
    from: '/recreation',
    to: routes.RECREATION_USES.path,
  },
  {
    from: '/campus-orientation',
    to: routes.CAMPUS_ORIENTATION_USES.path,
  },
  {
    from: '/edu/pricing',
    to: routes.EDU_PRICING.path,
  },
  {
    from: '/edu/pricing/school-wide-subscriptions',
    to: routes.EDU_WHY_BUY_A_SUBSCRIPTION.path,
  },
  {
    from: '/edu/game-library',
    to: routes.EXPERIENCE_LIBRARY.path,
  },
];

type PathToPageName = {
  [path: string]: keyof typeof routes;
};

// Create lookup of pathname to page name (the route key)
const pageNameFromPath = Object.keys(routes).reduce<PathToPageName>((acc, routeKey) => {
  const _routeKey = routeKey as keyof typeof routes;

  return {
    ...acc,
    [routes[_routeKey].path]: _routeKey,
  };
}, {});

export function getPageName(pathname: string): string {
  const parsedPathname =
    pathname.endsWith('/') && pathname.length > 2 ? pathname.substring(0, pathname.length - 1) : pathname;
  return pageNameFromPath[parsedPathname] || '404';
}

export function isEduPage(pathname: string): boolean {
  return pathname.startsWith('/edu');
}

export default routes;
